import { useIsFocused } from "@react-navigation/core";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, TextInput, TouchableOpacity, ScrollView } from "react-native";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { DataTable } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { ticketApi, useUpdateTicketStatusMutation } from "../../../../store/services/api/ticket-api";
import DateTimePicker from "../../components/navigation/controls/DateTimePicker";
import moment from 'moment';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import UpdatesList from "../Updates/UpdatesList";
import TicketUpdateModal from "./TicketUpdateModal";
import { ImageViewer } from "../../components/ImageViewer";
import PaginationComponent from "../../components/PaginationComponent";
import { DataTableHeader } from "../../components/DataTableHeader";
import DataTableCell from "../../components/DataTableCell";

export default function TicketsListScreen() {
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState<any>(false);
    const [trigger, ticketsResult] = ticketApi.useLazyGetTicketsQuery();
    const [page, setPage] = React.useState<number>(0);
    const [imagesToView, setImagesToView] = React.useState<any>([]);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [sortedColumn, setSortedColumn] = React.useState<string>("dateSubmittedFull");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);

    const [ticketNumber, setTicketNumber] = useState<any>("");
    const [dateRange, setDateRange] = useState("");
    const [startDate, setStartDate] = useState(moment().add(-1, 'week').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = useState(moment().format('DD/MM/YYYY'));
    const [ticketDescription, setTicketDescription] = useState<any>("");
    const [contactEmail, setContactEmail] = useState<any>("");
    const [status, setStatus] = useState<any>("All");
    const [selectedTicket, setSelectedTicket] = useState<any>("")
    const [isStatusChanged, setIsStatusChanged] = useState<any>(false)

    const [updateTicketStatus, updateTicketStatusresult] = useUpdateTicketStatusMutation();
    const [isUpdateModalvisible, setIsUpdateModalVisible] = useState<any>(false);
    const [ticketToUpdate, setTicketToUpdate] = useState<any>(null);

    const modalState = {
        isUpdateModalvisible,
        ticketToUpdate,
        setIsUpdateModalVisible
    }

    useEffect(() => {
        loadTable();
    }, [page, isFocused, isStatusChanged, numberOfItemsPerPage, sortedColumn, sortedColumnAscending])

    const resetFilters = () => {
        setTicketNumber("");
        setDateRange("");
        setStartDate(moment().add(-1, 'week').format('DD/MM/YYYY'));
        setEndDate(moment().format('DD/MM/YYYY'));
        setTicketDescription("");
        setContactEmail("");
        setStatus("");
    }

    const updateDateRange = (val) => {
        setDateRange(val);

        let startDate = moment();
        const endDate = moment();
        if (val == "-1") {
            startDate = moment().add(-1, 'week');
        } else {
            startDate = moment().add(-Number.parseInt(val), 'M');
        }

        setStartDate(moment(startDate).format('DD/MM/YYYY'));
        setEndDate(moment(endDate).format('DD/MM/YYYY'));
    }

    const loadTable = () => {
        const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        var dto = new PagedTableDto(page, {
            ticketNumber,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            ticketDescription,
            contactEmail,
            status,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending);
        trigger(dto).then(() => {
            setLoading(false);
        });
    }

    const toggleTicketStatus = async (element) => {
        try {
            const newStatus = element.status === "Closed" ? "Open" : "Closed";
            await updateTicketStatus({ id: element.id, status: newStatus });
            setIsStatusChanged(!isStatusChanged);
        } catch (error) {
            console.error("Error changing ticket status:", error);
        }
    };

    return (
        <ScrollView style={GlobalStyle.container}>
            {isUpdateModalvisible && <TicketUpdateModal modalState={modalState} />}
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <View style={styles.container}>
                {imagesToView.length > 0 && <ImageViewer images={imagesToView} onClosed={() => setImagesToView([])} />}
            </View>
            <View style={GlobalStyle.filters}>
                <View style={GlobalStyle.filters__header}>
                    <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
                </View>

                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Ticket Number</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setTicketNumber}
                            value={ticketNumber}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Date Range</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={dateRange}
                            onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

                            <Picker.Item label="Predefined Date Range" value="" />
                            <Picker.Item label="1 Week" value="-1" />
                            <Picker.Item label="1 Month" value="1" />
                            <Picker.Item label="3 Months" value="3" />
                            <Picker.Item label="6 Months" value="6" />
                            <Picker.Item label="12 Months" value="12" />

                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Start Date</Text>
                        <DateTimePicker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChange={setStartDate}
                            value={startDate}>
                        </DateTimePicker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>End Date</Text>
                        <DateTimePicker
                            style={[GlobalStyle.column, GlobalStyle.column__input,]}
                            onChange={setEndDate}
                            value={endDate}>
                        </DateTimePicker>
                    </View>

                </View>
                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Ticket Description</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setTicketDescription}
                            value={ticketDescription}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Contact email</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setContactEmail}
                            value={contactEmail}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Status</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={status}
                            onValueChange={(itemValue, itemIndex) => setStatus(itemValue)}>

                            <Picker.Item label="All" value="All" />
                            <Picker.Item label="Open" value="Open" />
                            <Picker.Item label="Closed" value="Closed" />

                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                                <SimpleLineIcons name="magnifier" size={16} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                                <SimpleLineIcons name="ban" size={16} color="black" />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    <DataTable style={GlobalStyle.table}>
                        <DataTableHeader
                            sortedColumn={sortedColumn}
                            sortedColumnAscending={sortedColumnAscending}
                            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                            headers={[
                                { text: 'Edit', visible: true, flex: 0.5 },
                                { text: 'Ticket Number', sortedName: "number", visible: true, flex: 0.5 },
                                { text: 'Date Submitted', sortedName: "dateSubmittedFull", visible: true },
                                { text: 'Asset Reference', sortedName: "assetCode", visible: true },
                                { text: 'Ticket Description', sortedName: "description", visible: true },
                                { text: 'Photos', visible: true, flex: 0.5 },
                                { text: 'Contact Email', sortedName: "contactEmail", visible: true },
                                { text: 'Status', sortedName: "status", visible: true, flex: 0.5 },
                                { text: 'Date of Status Change', sortedName: "dateofStatusChangeFull", visible: true },
                                { text: 'Time of Status Change', sortedName: "timeofStatusChangeFUll", visible: true },
                                { text: 'Change status', visible: true, flex: 0.5 },
                                { text: 'Updates', visible: true, flex: 0.5 },
                            ]}
                        />

                        {ticketsResult.data?.list.map((element: any) => {
                            return (
                                <DataTable.Row key={element.number}>
                                    <DataTableCell width={0.5}>
                                        <TouchableOpacity onPress={() => { setTicketToUpdate(element); setIsUpdateModalVisible(true); }}>
                                            <Entypo name="pencil" size={24} color="black" />
                                        </TouchableOpacity>
                                    </DataTableCell>
                                    <DataTableCell width={0.5}>{element.number}</DataTableCell>
                                    <DataTableCell>{element.dateSubmitted}</DataTableCell>
                                    <DataTableCell>{element.assetReference}</DataTableCell>
                                    <DataTableCell>{element.description}</DataTableCell>
                                    <DataTableCell width={0.5}>{element.images != null && element.images.length > 0 ?
                                        <TouchableOpacity onPress={() => setImagesToView(element.images)}>
                                            <MaterialIcons name={element.images.length === 1 ? "photo" : "photo-library"} size={24} color={PRIMARY_COLOUR} />
                                        </TouchableOpacity> : <Text>None</Text>
                                    }</DataTableCell>
                                    <DataTableCell>{element.contactEmail}</DataTableCell>
                                    <DataTableCell width={0.5}>{element.status}</DataTableCell>
                                    <DataTableCell>{element.dateOfStatusChange}</DataTableCell>
                                    <DataTableCell>{element.timeOfStatusChange}</DataTableCell>
                                    <DataTableCell width={0.5}>
                                        <TouchableOpacity onPress={() => toggleTicketStatus(element)}>
                                            {element.status === "Open" ? <Entypo name="circle-with-cross" size={24} color="black" /> : <Entypo name="cycle" size={24} color="black" />}
                                        </TouchableOpacity>
                                    </DataTableCell>
                                    <DataTableCell width={0.5}>
                                        <TouchableOpacity onPress={() => setSelectedTicket(element)}>
                                            <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
                                        </TouchableOpacity>
                                    </DataTableCell>
                                </DataTable.Row>
                            )
                        })}
                    </DataTable>

                    <PaginationComponent
                        numberOfItemsPerPage={numberOfItemsPerPage}
                        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                        page={page}
                        setPage={setPage}
                        fetchResult={ticketsResult}
                        setLoading={setLoading}
                    />
                </View>
                {selectedTicket &&
                    <View style={{ width: 450, marginLeft: 20 }}>
                        <UpdatesList ticket={selectedTicket} onClear={() => setSelectedTicket(null)} />
                    </View>
                }
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    }

});